import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import Attention from "../../components/Attention";
import Call from "../../components/Call";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const Fleischverkauf = (props) => {
  const products = props.data.directus.Produkte;
  const { Kopfzeile, Fusszeile, titel, description } =
    props.data.directus.fleischverkauf;
  return (
    <Layout bodyClass="page-teams">
      <SEO title={titel} description={description} slug="/fleischverkauf" />
      <div>
        <div className="container">
          <StaticImage
            src="../../images/fleisch_gegrillt.jpg"
            alt="Fleisch gegrillt"
            layout="constrained"
            height={400}
            width={1400}
            placeholder="blurred"
          />
        </div>
        <div className="container pt-md-10">
          <div className="row">
            <div className="col-12 col-8-md">
              <div className="service-single">
                <h1 className="title">{titel}</h1>
                <div
                className="content"
                  dangerouslySetInnerHTML={{ __html: Kopfzeile }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {products.map((product) => (
            <div key={product.id} className="col-12 col-md-6 mb-1">
              <div className="product card card-lifted">
                {product.image && (
                  <div className="card-image">
                    <GatsbyImage
                      alt={product.Name}
                      className="img-fluid mb-2"
                      image={
                        product.image.imageFile.childImageSharp.gatsbyImageData
                      }
                    />
                  </div>
                )}

                <h2 className="card-title">{product.Name}</h2>
                <div className="team-content">
                  {product.Beschreibung &&
                    product.Beschreibung.split("\n").map((i, key) => {
                      return <div key={key}>{i}</div>;
                    })}
                </div>
                <ul className="card-meta">
                  <li>
                    <strong>{`${product.Preis}€${product.festpreis ? '' : ' / 1 kg'}`}</strong>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container pb-10">
        <div className="row">
          <div className="col-12 col-8-md">
            <div className="service-single">
              <div className="content" dangerouslySetInnerHTML={{ __html: Fusszeile }} />
              <Call />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FleischQuery {
    directus {
      Produkte(
        filter: {status: {_eq: "anzeige"}, Verkauefer: {_eq: "fl"}}
        sort: "sort"
      ) {
        id
        Name
        Beschreibung
        Preis
        festpreis
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED)
            }
          }
        }
      }
      fleischverkauf {
        Fusszeile
        Kopfzeile
        titel
      }
    }
  }
`;

export default Fleischverkauf;
